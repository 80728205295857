import React from 'react'

import { t } from './components/Translator'
import { church_photography } from './constants'

const getPropertyValue = (imageObject: any, property: string) => {
    let captionObject
    if(imageObject.hasOwnProperty(property)) {
        captionObject = imageObject[property] === 'church_photography' ? church_photography : imageObject[property]
    }
    return !!captionObject ? t(captionObject) : undefined
}

export const getCaption = (imageObject: any) => {
    return getPropertyValue(imageObject, 'caption')
}

export const getSubCaption = (imageObject: any) => {
    return getPropertyValue(imageObject, 'sub_caption')
}

