import React, { useEffect, useState } from 'react'

const BackToTop: React.FC = () => {

    const [isVisible, setIsVisible] = useState(false)

    useEffect(() => {

        const scrollTopOffset = 550
        let didScroll = false
        const handleScrollForBackToTop = (e: Event) => { didScroll = true }
        window.addEventListener('scroll', handleScrollForBackToTop)

        const intervalForBackToTop = setInterval(() => {
            if(didScroll) {
                didScroll = false
                const scrollTop = window.pageYOffset
                if(!isVisible && scrollTop > scrollTopOffset) {
                    // console.log('** setIsVisible TRUE - scrollTop['+scrollTop+']')
                    setIsVisible(true)
                }
                else if(isVisible && scrollTop <= scrollTopOffset) {
                    // console.log('** setIsVisible FALSE - scrollTop['+scrollTop+']')
                    setIsVisible(false)
                }
            }
        }, 200);

        return () => {
            clearInterval(intervalForBackToTop);
            window.removeEventListener('scroll', handleScrollForBackToTop)
        }
    })

    const onClick = () => {
        // see https://css-tricks.com/snippets/jquery/smooth-scrolling/
        window.scroll({top: 0, left: 0, behavior: 'smooth'})
    }

    return (
        <a id="back-to-top" onClick={onClick} style={{display: `${isVisible ? 'inline-block' : 'none'}`}}>
            <i className="fa fa-chevron-up"></i>
        </a>
    )
}

export default BackToTop
