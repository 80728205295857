import React, {useContext} from 'react'

// @ts-ignore
import Helmet from 'react-helmet'

// import Header from '@components/Header'
import Header from './Header'
import Footer from './Footer'
import { AppContext } from './ContextProvider'
import {IBreadcrumb, ISection, ILayout, IBanner, ISectionUntranslated} from '../types/index'
import { t } from './Translator'
import Carousel from './Carousel'
// @ts-ignore
import { Link } from 'gatsby'
import { getCaption } from '../utils'

export const Breadcrumb: React.FC<IBreadcrumb> = ({ content}) => {
    const context = useContext(AppContext)

    const parentLinkLabel = t(content.parentLinkLabel)
    const currentLinkLabel = t(content.currentLinkLabel)

    // (!) setNewCurrentNavigationLink() also caches current locale in new context
    //     only works with Gatsby Link but fails with a href oncClick
    const onClick = () => {
        context.setNewCurrentNavigationLink(content.parentLink)
    }

    return (
        <div className="notice-bar">
            <div className="container">
                <ol className="breadcrumb">
                    <li><Link to={content.parentLink} onClick={onClick}>{parentLinkLabel}</Link></li>
                    <li className="active">{currentLinkLabel}</li>
                </ol>
            </div>
        </div>
    )
}

export const Section: React.FC<ISection> = ({ content}) => {
    const html = t(content)

    return (
        <div className="right-caret-ul" dangerouslySetInnerHTML={{ __html: html }} />
    )
}

export const SectionUntranslated: React.FC<ISectionUntranslated> = ({ html}) => {
    return (
        <div className="ol-colored" dangerouslySetInnerHTML={{ __html: html }} />
    )
}

const Banner: React.FC<IBanner> = ({ content }) => {
    const context = useContext(AppContext)
    const caption = getCaption(content)

    return (
        <div className="banner-area fade-in">
            <div className="page-header parallax"
                // see https://stackoverflow.com/questions/39195687/setting-a-backgroundimage-with-react-inline-styles
                 style={{backgroundImage: `url("${content.image_src}")`}}
                 onClick={() => context.setImageModal({ src: content.image_src, caption: !!caption ? caption : '' })}>
                <div>
                    <div><span>{t(content.title)}</span></div>
                </div>
            </div>
        </div>
    )
}

const ImageModal: React.FC = () => {

    const context = useContext(AppContext)

    // (!) undefined checks to enable 'gatsby build'
    let imageModal = { src:'', caption:'' }
    if(!context || !context.imageModal) {
        // console.error(`# ImageModal ${!context ? 'context' : 'context imageModal'} undefined`)
    }
    else {
        imageModal = context.imageModal
    }

    return (
        <div className="modal-container"
             style={imageModal.src === '' && imageModal.caption === '' ? {display:'none'} : {display: 'block'}}>
            {/*&times; displays a 'X' for closing the modal */}
            <div className="modal-close" onClick={() => context.setImageModal({src:'', caption:''})}>&times;</div>
            <img className="modal-image" src={imageModal.src} alt=""/>
            <div className="modal-caption">{imageModal.caption}</div>
        </div>
    )
}

const Layout: React.FC<ILayout> = ({ head, banner, breadcrumb, carouselImages, children }) => {

    // todo: when navigating to other page, Layout renders 2x: 1st for old page, 2nd for new page
    // todo: except when navigating away from the home page. Why?
    // todo: not impacted by omitting local prop const banner
    // console.log('# Layout rendering - banner['+ (!!banner ? banner.title.nl : 'NONE') +']')

    return (
        <div>
            {/* Helmet example from https://reactgo.com/react-seo-helmet/ */}
            <Helmet>
                <title>{t(head.title)}</title>
                <meta name="description" content={t(head.description)}/>
                <meta name="keywords" content={t(head.keywords)}/>
            </Helmet>
            <Header/>
            <ImageModal/>
            {!!breadcrumb && <Breadcrumb content={breadcrumb}/>}
            {!!banner && <Banner content={banner}/>}
            {!!carouselImages &&
                <div className="hero-area fade-in">
                    {/*@ts-ignore*/}
                    <Carousel images={carouselImages}/>
                </div>}
            <div className="main" role="main">
                <div id="content" className="content full fade-in">
                    <div className="container">{children}</div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default Layout