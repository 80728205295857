import React from 'react'

import { links } from '../constants'
import { NavigationLink } from './Header'
import { ENavigationLink } from '../types/index'
import BackToTop from './BackToTop'
import { t } from './Translator'
import { Section } from '../components/Layout'
// @ts-ignore
import content from '../../content/footer.yaml'
// @ts-ignore
import contact from '../../content/contact.yaml'

// https://stackoverflow.com/questions/26882177/react-js-inline-style-best-practices
// todo: move custom styling from footer.yaml to here - usage <address style={styles.marginTop_13}>
const styles = {
    marginTop_10: { marginTop: '10px' },
    marginTop_13: { marginTop: '13px' },
    marginTop_18: { marginTop: '18px' },
    marginTop_20: { marginTop: '20px' },
    colorBlack:   { color: '#000000'}
}

const Footer: React.FC = () => {

    const address = t(content.address)
    const copyright = t(content.copyright)
    const hours = content.opening_hours
    const celebrations = content.celebrations

    // contact phone number and e-mail address
    const phone = contact.contact_cathedral.phone
    const email = contact.contact_cathedral.email

    return (
        <>
            {/*todo: several 'XML tag has empty body' may produce issues*/}
            {/*(!) REMARK: Bootstrap: Extra small <576px; Small ≥576px; Medium ≥768px; Large ≥992px; Extra large ≥1200px*/}
            <footer className="site-footer">
                <div className="container">
                    <div className="row">
                        <div className="offset-sm-1 col-md-5 offset-md-1 col-lg-5 offset-lg-1 col-xl-3 offset-xl-0">
                            <div className="widget footer-widget">
                                <img src="/images/logo_transparent.png" alt=""/>
                                <div style={styles.marginTop_20}>
                                <span>
                                    {/*using a href for external routing target="_blank" - security setting rel="noreferrer noopener"*/}
                                    {/*Gatsby link only for internal routing - does not support target="_blank" - see https://github.com/gatsbyjs/gatsby/issues/13825*/}
                                    <a href="https://www.google.be/maps/place/Sint-Salvatorskathedraal/@51.20552,3.2194807,17z/data=!3m1!4b1!4m5!3m4!1s0x47c350daffda0ecd:0x42d496e81d83e0a!8m2!3d51.2055167!4d3.2216694"
                                       target="_blank" rel="noreferrer noopener">
                                        <i className="fa fa-map-marker"></i>
                                        {address}
                                    </a>
                                </span>
                                </div>
                                <div style={styles.marginTop_10}>
                                    <span><i className="fa fa-phone"></i>{phone}</span>
                                </div>
                                <div style={styles.marginTop_10}>
                                    <span>
                                        <i className="fa fa-envelope"></i>
                                        <a href={`mailto:${email}`}>{email}</a>
                                    </span>
                                </div>
                                <div style={styles.marginTop_20}>
                                    <img src="/images/logo_provincie_w-vl.svg" alt=""/>
                                </div>
                            </div>
                        </div>
                        <div className="offset-sm-1 col-md-6 offset-md-0 col-lg-6 offset-lg-0 col-xl-2 offset-xl-0">
                            <div className="widget footer-widget widget_links right-caret-ul">
                                <h4 className="widget-title" style={{marginBottom: '30px'}}>sitemap</h4>
                                <ul>
                                    {links.map((link, index) =>
                                        <NavigationLink key={index} type={ENavigationLink.Footer} to={link.to} label={t(link.label)}/>
                                    )}
                                </ul>
                            </div>
                            <div className="footer-spacer"></div>
                        </div>
                        <div className="offset-sm-1 col-md-5 offset-md-1 col-lg-5 offset-lg-1 col-xl-3 offset-xl-0">
                            <Section content={hours}/>
                        </div>
                        <div className="offset-sm-1 col-md-6 offset-md-0 col-lg-6 offset-lg-0 col-xl-4 offset-xl-0">
                            <Section content={celebrations}/>
                        </div>
                    </div>
                </div>
            </footer>
            <footer className="site-footer-bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-md copyrights-left">
                            <p>{copyright}</p>
                        </div>
                        <div className="col-md copyrights-right">
                            <ul className="pull-right social-icons-colored">
                                <li className="facebook">
                                    <a href="https://www.facebook.com/SintSalvatorskathedraal" target="_blank" rel="noreferrer noopener">
                                        <i className="fa fa-facebook-f"></i>
                                    </a>
                                </li>
                                {/*<li className="youtube"><a href="#"><i className="fa fa-youtube"></i></a></li>*/}
                                {/*<li className="twitter"><a href="#"><i className="fa fa-twitter"></i></a></li>*/}
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
            <BackToTop/>
        </>
    )
}

export default Footer