import React, {useEffect, useState} from 'react'

import { ICarousel, ICarouselItem } from '../types/index'
import { getCaption, getSubCaption } from '../utils'

const Item: React.FC<ICarouselItem> = ({ image, active }) => {

    const caption = getCaption(image)
    const subCaption = getSubCaption(image)

    return (

        /**
         * TODO evaluate CAPTION header (top of page) carousel http://carkus.com/bower_components/bootstrap.native/
         * TODO HOW is that beautiful animation caption done? Different animation for PREV and NEXT
         *          extract that css animation code via browser inspect
         */
        <div className={`carousel-item ${active && 'active'}`}>
            {/* <img className="d-block w-100" src={image.src} alt=""/> */}
            {/*
                https://css-tricks.com/preventing-content-reflow-from-lazy-loaded-images/
                to avoid lazy-load jank, all you have to do is put the correct natural width and height attributes
                on images and they will load nicely, even if CSS makes the image fluid with
                rdm: apparently all values width & height are OK as long as
                    - width is exactly 2 x height
                    - both ar larger than the image container width & height
                    - css class carousel-image-placeholder defines 'width: 100%;'
             */}
            <img className="carousel-image-placeholder" width="800" height="400" src={image.src} alt=""/>
            {/* different animation for PREV and NEXT */}
            <div className="carousel-caption">
                {/* <div className="carousel-caption d-none d-md-block"> */}
                {!!caption && <p>{caption}</p>}
                <p className="carousel-sub-caption">{!subCaption ? <br/> : subCaption}</p>
            </div>
        </div>
    )
}

// Bootstrap Carousel from https://getbootstrap.com/docs/4.0/components/carousel/
const Carousel: React.FC<ICarousel> = ({ images }) => {

    const [isCarouselCycleLaunched, setIsCarouselCycleLaunched] = useState(false)

    useEffect(() => {

        /**
         (!) BSN = Bootstrap Native JS without JQuery dependencies
         google 'bootstrap without jquery' (as expected in upcoming Bootstrap 5)
             http://carkus.com/bower_components/bootstrap.native/
             https://dev.to/tamb/bootstrap-native-bootstrap-without-jquery-today-59b6
             https://github.com/thednp/bootstrap.native
             https://github.com/thednp/bootstrap.native/wiki/How-to-use#working-locally
             (!) Carousel code samples: http://thednp.github.io/bootstrap.native/
         */
        // carousel reference no longer used in return()
        // let carousel: { dispose: () => void, cycle: () => void }
        // isCarouselCycleLaunched check to avoid memory leakage
        if(!isCarouselCycleLaunched) {
            const Carousel = require('bootstrap.native/dist/components/carousel-native')
            // const BSN = require('bootstrap.native')
            // const carousel = new BSN.Carousel('.carousel', options)
            const carousel = new Carousel('#homepage-carousel', {
                // interval: 9000,
                // pause: 'hover',
                // ride: 'carousel',
                keyboard: false
            })
            carousel.cycle()
            console.log('# Carousel useEffect() - carousel cycle launched')
            setIsCarouselCycleLaunched(true)
        }

        // (!) slid & slide event handling
        // carousel.on('slide.bs.carousel', ...) as in https://getbootstrap.com/docs/4.0/components/carousel/
        // fails - TypeError: carousel.on is not a function
        // todo evaluate event handling from http://carkus.com/bower_components/bootstrap.native/#exampleCarousel
        // maybe needed for caption fade in/out animation
        // demo myCarousel demonstrating the slid and slide events
        /**
        const mainSlider = document.getElementById('carousel');
        mainSlider.addEventListener('slid.bs.carousel', (e) => {
            console.log('** Carousel -- slid.bs.carousel event')
            // get the caption of current active item before slide
            const active = mainSlider.querySelector('.carousel-item.active .carousel-caption');
            active.classList.remove('slide')
        });
        mainSlider.addEventListener('slide.bs.carousel', (e) => {
            console.log('** Carousel -- slide.bs.carousel event')
            // get the caption of new active item after slide
            const active = mainSlider.querySelector('.carousel-item.active .carousel-caption');
            active.classList.add('slide')
        });
         */

        return () => {
            // (!) dispose() results in ERROR in PROD Netlify: carousel-native.js:301 Uncaught TypeError:
            //     Cannot read property 'Carousel' of null at HTMLDivElement.I (carousel-native.js:301)
            // if(!!carousel) carousel.dispose()
        }

    }, [isCarouselCycleLaunched])

    // console.log('# Carousel - isMobile['+isMobile+'] imageHeight['+imageHeight+'] height['+height+']')

    return (
        <div id="homepage-carousel" className="carousel slide carousel-fade" data-bs-ride="carousel"
                                    data-bs-interval="8000" data-bs-touch="true" data-bs-pause="hover">
            <div id="carousel-indicators-container">
                <ol className="carousel-indicators">
                    {images.map((image, index) =>
                        <li key={index} data-bs-target="#homepage-carousel" data-bs-slide-to={index}
                            className={`${index === 0 ? 'active' : ''}`}>
                        </li>
                    )}
                </ol>
            </div>
            <div id="carousel-inner" className="carousel-inner">
                {images.map((image, index) =>
                    <Item key={index} image={image} active={index === 0}/>
                )}
                <div>
                    <a href="#homepage-carousel" className="carousel-control-prev" role="button" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    </a>
                    <a href="#homepage-carousel" className="carousel-control-next" role="button" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Carousel
